<template>
        <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
            <Alerts :_error="error" :_success="success"></Alerts>

          <div v-if="!wait && survey == null"
               class="alert alert-info">
            <h6 class="mb-1"><strong>Just a second.. </strong>
            </h6>
            <p class="mb-0">Retrieving survey questions to preview..</p>
          </div>

          <div v-if="!wait && survey == null" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

            <!-- Empty store shows if there's an error -->
            <div v-if="error" class="posts-empty">
                <div class="posts-empty-inner">
                    <div class="posts-empty-inner_img">
                        <img loading="lazy" src="@/assets/img/error-message.png" width="80" height="80" alt="Error message"/>
                    </div>
                    <h4>{{ error }}</h4>
                    <h6>An error occurred while trying to preview your survey.</h6>
                </div>
            </div>            

            <div v-if="survey">
                <div class="preview-title">
                    <h1>{{ survey.title }}</h1>
                    <Alerts :_success="success" :_error="error"></Alerts>
                </div>

                <div class="preview">
                    <div v-if="survey.description" class="survey-desc">
                        <p class="mb-0 font-weight-semibold">Survey Description</p>
                        <p class="text-secondary mb-3">{{ survey.description }}</p>
                    </div>
                    <!-- <QuestionnairePreview v-if="survey.questions_count !== 0" :survey="survey" :respond="false"></QuestionnairePreview> -->
                    <Questionnaire v-if="survey.questions_count !== 0" :survey="survey" :respond="false"></Questionnaire>
                    <!-- Empty store shows if there are no questions -->
                    <div v-else class="posts-empty mt-3">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/empty-document.png" width="80" height="80" alt="Error message"/>
                            </div>
                            <h4>This survey has no questions.</h4>
                            <h6>Your survey appears to be empty. Please
                                <router-link class="font-weight-semibold"
                                    :to="`/profile/surveys/view/${this.code}/questionnaire`"> add some questions.
                                </router-link>                                
                            </h6>
                        </div>
                    </div>                     
                    <div v-if="!survey.published">
                        <button v-if="!survey.questions_count == 0" :disabled="wait" class="custom-btn custom-btn-success me-1"
                                @click.prevent="publishSurvey()">
                            <template v-if="wait">Please wait...</template>
                            <template v-else>Publish</template>
                        </button>
                        <router-link :to="`/profile/surveys/view/${survey.code}/questionnaire`" :disabled="wait">
                            <button class="custom-btn custom-btn-primary">
                            <font-awesome-icon icon="pen" />
                            Edit Form
                            </button>
                        </router-link>
                    </div>

                    <div v-if="survey.published && survey.respondents_count == 0">
                        <div class="alert alert-info">Your survey has not collected any responses yet. Click 'unpublish' if you would like to add or edit questions.</div>
                        <button class="custom-btn custom-btn-danger me-1"
                                @click.prevent="unPublishSurvey()">
                            <template v-if="wait">Unpublishing, please wait...</template>
                            <template v-else>
                                <font-awesome-icon icon="undo" />
                                Unpublish Survey
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import Questionnaire from "../../Helpers/Questionnaire";
import Alerts from "../../Helpers/Alerts";
export default {
    name: "SurveyPreview",
    components: {Alerts, Questionnaire},
    props: ['code'],

    data() {
        return {
            survey: null,
        }
    },
    mounted() {
        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
        }).catch(error => {
            this.showError(error)
        });
    },
    methods: {
        publishSurvey() {
            if (confirm('Are you sure to publish this survey?')) {
                this.wait = true
                window.scroll(0, 0)
                this.success = 'Publishing survey, please wait...'
                this.$http.post(`/api/profile/survey/${this.code}/publish`).then(response => {
                    this.survey = response.data.data
                    this.success = 'Survey published successfully'
                }).catch(error => {
                    this.showError(error)
                }).then(() => {
                    this.wait = false
                    this.success = ''
                })
            }
        },
        unPublishSurvey() {
            if (confirm('Are you sure you want to UNPUBLISH this survey?')) {
                this.wait = true
                window.scroll(0, 0)
                this.success = 'Unpublishing survey, please wait...'
                this.$http.post(`/api/profile/survey/${this.code}/unpublish`).then(response => {
                    this.survey = response.data.data
                    this.success = 'Survey unpublished successfully'
                }).catch(error => {
                    this.showError(error)
                }).then(() => {
                    this.wait = false
                    this.success = ''
                })
            }
        }
    }
}
</script>

<style scoped>
</style>